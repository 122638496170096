@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Press+Start+2P&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background:black;
  color:white;
  font-family: var(--font-text);
}

.landing__content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  border: 3px solid var(--color-card-text);
  text-align: center;
  overflow: hidden;
}

.landing__card {
  max-width: 100%;
}

input[type="button"],
button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: x-small;
  font-family: var(--font-stylized);
  border: 1px solid var(--color-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-s) var(--spacing-m);
  margin-top: var(--spacing-l);
  background: black;
  color: white;  
}

input[type="button"]:hover, button:hover {
  cursor: pointer;
  background: white;
  color: black;
}

button {
  font-size: small;
  width: 200px;
  padding: var(--spacing-m) var(--spacing-m);
}

input[type="text"] {
  border: 3px solid var(--color-text);
  box-sizing: border-box;
  padding: var(--spacing-s) var(--spacing-s);
  width: 100%;
}

.title{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 86%;
  margin-left:auto;
  margin-right:auto;
  box-sizing: border-box;
  margin-bottom:var(--spacing-xxl);

  h1{  
    justify-content: center;
    color:var(--color-primary);
    font-family:var(--font-stylized);
    text-align:center;
    margin-top:var(--spacing-xl);
    margin-bottom:0;
    font-size:22px;
  }
  
  img{
    margin-top: var(--spacing-xl);
    height:24px;
    margin-left:auto;
  }

  @media (max-width: 900px){
    width: 96%;
    margin-bottom:var(--spacing-m);
    h1, img{
      margin-top:var(--spacing-m);
    }
  }
}

h2 {
  font-family: var(--font-stylized);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  text-transform: uppercase;

  @media (max-width: 900px){
    margin-top: var(--spacing-m);
    font-size:18px;
  }
}

p {
  margin-bottom: var(--spacing-l);
  font-family:var(--font-text);
  font-weight:bold;
  font-size:25px;
  line-height:50px;

  @media (max-width: 900px){
    font-size:18px;
    line-height:40px;
    margin:var(--spacing-s);
  }
}

.landing__options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing__option {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
  border: 1px solid var(--color-text);
  border-radius: var(--border-radius);
  padding: var(--spacing-m) var(--spacing-m);
  margin-top: var(--spacing-l);
  width: 200px;  
}

.landing__option:hover {
  cursor: pointer;
  background: white;
  color: black;
}

.landing__stored {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-xl);
}

.landing__stored div{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: small;
  background: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius);
  padding: var(--spacing-s) var(--spacing-s);
  margin-bottom: var(--spacing-m);
  min-width: 160px;
}

.landing__group {
  display: flex;
  width: 100%;
  margin-bottom: var(--spacing-l);
}

.landing__group .landing__value {
  width: 100px;
  text-transform: uppercase;
  margin-top: var(--spacing-s);

  @media (max-width: 900px){
    text-align: start;
  }
}

.landing__group .landing__action {
  width: 100%;
  text-align: left;
}

.landing__group .landing__action .landing__action-description {
  font-size: small;
  margin-top: var(--spacing-s);
}

.landing__errors {
  color: red;
  margin-top: var(--spacing-m);
}

.modal__container{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,.2);
  backdrop-filter: blur(4px);
}

.modal{
  position:fixed;
  display:flex;
  justify-content: center;
  text-align: center;
  top:120px;
  left:18%;
  right:18%;
  bottom:10%;
  border-radius:var(--border-radius);
  border:3px solid rgba(255,255,255,.4);
  background-color:black;
  padding:var(--spacing-m);
  animation: open-modal 0.4s ease-in-out forwards;

  @media (max-width: 900px){
    left:var(--spacing-xxs);
    right:var(--spacing-xxs);
    bottom:var(--spacing-xxs);
  }
}

@keyframes open-modal {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  75% {
    transform: translateY(-3%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

:root {
  --spacing-xxl: 6rem;
  --spacing-xl: 4rem;
  --spacing-l: 2rem;
  --spacing-m: 1rem;
  --spacing-s: 0.6rem;
  --spacing-xs: 0.4rem;
  --spacing-xxs: 0.2rem;

  --border-radius: 1.6rem;

  --font-text: "IBM Plex Mono";
  --font-stylized: "Press Start 2P";

  --color-primary-base: 247, 139, 5;
  --color-primary: rgba(var(--color-primary-base), 1);
  --color-text: white;

  --header-height: 80px;
  --dock-height: 120px;
}